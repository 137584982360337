import { FC } from "react";
import { Footer, Header } from "./components";
import { MainProps } from "./interfaces";

export const Main: FC<MainProps> = (props) => {
  const {
    children,
    hasFooter = true,
    isDarkTheme = false,
    isDesktop,
    isLanding = false,
    t,
    tailwindStyle = "",
  } = props;

  return (
    <div>
      <Header
        isDarkTheme={isDarkTheme}
        isDesktop={isDesktop}
        isLanding={isLanding}
        t={t}
      />
      <main className={tailwindStyle}>{children}</main>
      {hasFooter && <Footer t={t} isDesktop={isDesktop} />}
    </div>
  );
};
