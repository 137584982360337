import { FC, Fragment } from "react";
import i18n from "i18next";
import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import MexicoFlag from "../../../../../resources/icons/mexico.svg";
import { LanguageSelectorProps } from "../../../interfaces";

export const LanguageSelector: FC<LanguageSelectorProps> = (props) => {
  const { isDarkTheme, isDesktop } = props;

  const onChangeLanguage = (value: string): void => {
    if (value === "es") {
      i18n.changeLanguage("en");
      return;
    }
    i18n.changeLanguage("es");
  };

  return (
    <Popover className="my-auto">
      <PopoverButton
        id={`language-topbar-${isDesktop ? "desktop" : "mobile"}`}
        className="flex gap-3 p-1.5 items-center focus:outline-none"
      >
        <img src={MexicoFlag} className="h-3" alt="Mexico flag" />
        <p className={`${isDarkTheme ? "text-white" : "text-black"} text-sm`}>
          {i18n.language.slice(0, 2) === "es"
            ? "México - Español"
            : "Mexico - English"}
        </p>
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor={isDesktop && "bottom"}
          className="bg-white border border-gray-200 mt-3 p-1.5 rounded-lg z-[1000]"
        >
          <CloseButton
            className="w-full flex gap-3 items-center hover:bg-gray-50 px-3 py-2 rounded-lg cursor-pointer"
            onClick={() => onChangeLanguage(i18n.language.slice(0, 2))}
          >
            <img src={MexicoFlag} className="h-3" alt="Mexico flag" />
            <p className="text-sm">
              {i18n.language.slice(0, 2) === "es"
                ? "Mexico - English"
                : "México - Español"}
            </p>
          </CloseButton>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};
