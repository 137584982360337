import { useTranslation } from "react-i18next";
import { useDynamicResolution } from "../../hooks";
import { Main } from "../Main/Main";
import { TermsAndConditions } from "./components";

const Terms = () => {
  const { t } = useTranslation();
  const isDesktop: boolean = useDynamicResolution();

  return (
    <Main isDesktop={isDesktop} t={t}>
      <TermsAndConditions />
    </Main>
  );
};

export default Terms;
