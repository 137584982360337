import { FC } from "react";
import SimplifySteps from "../../../resources/images/simplify-steps.webp";
import AdvancedTerminals from "../../../resources/images/advanced-terminals.webp";
import ReverseATM from "../../../resources/images/reverse-atm.webp";
import { SectionProps } from "../../../interfaces";

export const HowItWorks: FC<SectionProps> = ({ t }) => {
  return (
    <section
      id="how-it-works"
      className="bg-white py-16 lg:py-24 px-[5%] sm:px-[3%]"
    >
      <div className="max-w-[80rem] mx-auto">
        <h2 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium">
          {t("home.howItWorks")}
        </h2>
        <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400 mt-6">
          {t("home.yoshiEliminates")}
        </p>
        <div className="mt-12 lg:mt-20 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 items-center">
          <div>
            <h3 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium">
              {t("home.simplifySteps")}
            </h3>
            <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400 mt-6">
              {t("home.inAConventionalCashlessSystem")}
            </p>
          </div>
          <div className="size-full">
            <img
              loading="lazy"
              src={SimplifySteps}
              className="h-52 mx-auto object-contain"
              alt="Simplify steps"
            />
          </div>
        </div>
        <div className="mt-4 lg:mt-20 grid grid-cols-1 md:grid-cols-2 items-center gap-4 lg:gap-16">
          <div className="size-full order-2 md:order-1">
            <img
              loading="lazy"
              src={AdvancedTerminals}
              className="h-52 mx-auto object-contain"
              alt="Advanced terminals"
            />
          </div>
          <div className="order-1 md:order-2">
            <h3 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium">
              {t("home.advancedTerminals")}
            </h3>
            <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400 mt-6">
              {t("home.ourSolutionFacilitates")}
            </p>
          </div>
        </div>
        <div className="mt-4 lg:mt-20 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 items-center">
          <div>
            <h3 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium">
              {t("home.reverseATMs")}
            </h3>
            <p className="text-base lg:text-lg/relaxed text-gray-400 mt-6">
              {t("home.forThoseWhoDontHaveBankCards")}
            </p>
          </div>
          <div className="size-full">
            <img
              loading="lazy"
              src={ReverseATM}
              className="h-52 mx-auto object-contain"
              alt="Reverse ATM"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
