import { FC } from "react";
import AcceptPayments from "../../../resources/images/accept-payments.webp";
import { SectionProps } from "../../../interfaces";

export const Terminals: FC<SectionProps> = ({ t }) => {
  return (
    <section id="terminals" className="bg-primary py-16 lg:py-24 px-[5%] sm:px-[3%]">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 items-center max-w-[80rem] mx-auto">
        <h2 className="text-2xl/tight lg:text-3xl/tight text-white">
          {t("home.acceptPayments")}
        </h2>
        <div className="size-full">
          <img
            loading="lazy"
            src={AcceptPayments}
            className="size-full mx-auto object-contain"
            alt="Accepted payments"
          />
        </div>
      </div>
    </section>
  );
};
