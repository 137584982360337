import { FC } from "react";
import RealTimeSales from "../../../resources/images/yoshi-dashboard.webp";
import DataIsEverything from "../../../resources/images/data-everything.webp";
import IntegrateTicketing from "../../../resources/images/integrate-ticketing.webp";
import { SectionProps } from "../../../interfaces";

export const Features: FC<SectionProps> = ({ t }) => {
  return (
    <section>
      <div className="overflow-hidden h-16 lg:h-36">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          className="size-full"
        >
          <path
            d="M0.57,150.50 C514.67,-6.41 638.82,-53.77 500.00,49.99 L500.00,150.00 L177.76,153.46 Z"
            className="stroke-none fill-purple-50/30"
          ></path>
        </svg>
      </div>
      <div id="data">
        <div className="bg-purple-50/30 py-16 lg:py-24 px-[5%] sm:px-[3%] overflow-x-hidden">
          <div className="max-w-[80rem] mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 items-center max-w-[80rem] mx-auto">
              <div className="size-full order-2 md:order-1">
                <img
                  loading="lazy"
                  src={RealTimeSales}
                  className="h-44 sm:h-64 lg:h-72 mx-auto object-contain"
                  alt="Real time sales"
                />
              </div>
              <div className="order-1 md:order-2">
                <h3 className="text-2xl/tight lg:text-3xl/tight font-medium">
                  {t("home.visualizeYourSalesInRealTime")}
                </h3>
                <ol className="list-outside list-disc pl-5 mt-6 text-gray-400">
                  <li className="text-base/relaxed lg:text-lg/relaxed">
                    {t("home.knowYourSalesDetail")}
                  </li>
                  <li className="text-base/relaxed lg:text-lg/relaxed mt-4">
                    {t("home.exportYourReports")}
                  </li>
                  <li className="text-base/relaxed lg:text-lg/relaxed mt-4">
                    {t("home.configurePromotionsAndDiscounts")}
                  </li>
                </ol>
              </div>
            </div>
            <div className="mt-12 md:mt-20 grid grid-cols-1 md:grid-cols-2 items-center gap-12 md:gap-16">
              <div>
                <h3 className="text-2xl/tight lg:text-3xl/tight font-medium">
                  {t("home.dataIsEverything")}
                </h3>
                <ol className="list-outside list-disc pl-5 mt-6 text-gray-400">
                  <li className="text-base/relaxed lg:text-lg/relaxed">
                    {t("home.accessToYourSalesInfo")}
                  </li>
                  <li className="text-base/relaxed lg:text-lg/relaxed mt-4">
                    {t("home.findOutBestSellingProducts")}
                  </li>
                </ol>
              </div>
              <div className="size-full">
                <img
                  loading="lazy"
                  src={DataIsEverything}
                  className="h-72 lg:h-96 mx-auto object-contain"
                  alt="Data is everything"
                />
              </div>
            </div>
            <div className="mt-12 md:mt-20 grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 items-center">
              <div className="size-full order-2 md:order-1">
                <img
                  loading="lazy"
                  src={IntegrateTicketing}
                  className="h-72 lg:h-96 mx-auto object-contain"
                  alt="Integrate ticketing"
                />
              </div>
              <div className="order-1 md:order-2">
                <h3 className="text-2xl/tight lg:text-3xl/tight font-medium">
                  {t("home.integrateTicketingOfYourEvent")}
                </h3>
                <ol className="list-outside list-disc pl-5 mt-6 text-gray-400">
                  <li className="text-base/relaxed lg:text-lg/relaxed">
                    {`${t("home.connectTicketingOfYourEvent")} `}
                    <b>{t("home.boletomovil")}</b>
                    {` ${t("home.withFoodAndBeverageSales")}`}
                  </li>
                  <li className="text-base/relaxed lg:text-lg/relaxed mt-4">
                    {t("home.knowTheCompleteJourney")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
