import { FC } from "react";
import { Link, Navigate } from "react-router-dom";
import i18n from "i18next";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SectionProps } from "../../../../interfaces";

export const Footer: FC<SectionProps> = (props) => {
  const { t, isDesktop } = props;

  const onChangeLanguage = (value: string): void => {
    if (value === "Español") {
      i18n.changeLanguage("es");
      return;
    }
    i18n.changeLanguage("en");
  };

  return (
    <footer className="w-full bg-white border-t border-t-gray-200 py-14 px-[5%] sm:px-[3%]">
      <div className="max-w-[80rem] mx-auto">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="flex flex-col gap-6">
            <h2 className="text-base/relaxed lg:text-lg/relaxed text-gray-500 font-medium">
              {t("home.company")}
            </h2>
            <div className="flex flex-col gap-3">
              {/* <a
                id={`about-us-${isDesktop ? "desktop" : "mobile"}`}
                href="#about-us"
                className="w-fit text-gray-400 hover:text-gray-500"
              >
                {t("home.aboutUs")}
              </a>
              <a
                id={`privacy-${isDesktop ? "desktop" : "mobile"}`}
                href="#privacy"
                className="w-fit text-gray-400 hover:text-gray-500"
              >
                {t("home.privacyPolicy")}
              </a> */}
              <Link
                id={`terms-${isDesktop ? "desktop" : "mobile"}`}
                className="w-fit text-gray-400 hover:text-gray-500"
                to="/terminos"
              >
                {t("home.termsAndConditions")}
              </Link>
            </div>
          </div>
          <div className="w-2/3 lg:w-1/3 flex flex-col gap-6 ml-0">
            <h2 className="text-base/relaxed lg:text-lg/relaxed text-gray-500 font-medium">
              {t("home.language")}
            </h2>
            <div className="relative w-full">
              <select
                id={`language-footer-${isDesktop ? "desktop" : "mobile"}`}
                name={`language-footer-${isDesktop ? "desktop" : "mobile"}`}
                className="appearance-none block w-full bg-none bg-white border border-gray-200 rounded-md py-2 pl-3 pr-10 text-sm focus:outline-none focus:ring-primary focus:border-primary"
                onChange={(e) => onChangeLanguage(e.target.value)}
                value={
                  i18n.language.slice(0, 2) === "es" ? "Español" : "English"
                }
              >
                <option>English</option>
                <option>Español</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                <ChevronDownIcon
                  className="h-3 w-3 text-gray-400 stroke-[3]"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
