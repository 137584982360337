import { FC } from "react";
import { TopbarDesktop, TopbarMobile } from "./Topbar";
import { HeaderProps } from "../interfaces";

export const Header: FC<HeaderProps> = (props) => {
  const { isDarkTheme, isDesktop, isLanding, t } = props;

  return isDesktop ? (
    <TopbarDesktop isDarkTheme={isDarkTheme} isLanding={isLanding} t={t} />
  ) : (
    <TopbarMobile isDarkTheme={isDarkTheme} isLanding={isLanding} t={t} />
  );
};
