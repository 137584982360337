import { useState, useEffect } from "react";

export const useDynamicResolution = (): boolean => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const desktopMediaQuery: MediaQueryList =
    window.matchMedia("(min-width: 768px)");

  const comproveMedia = (event: MediaQueryListEvent): void => {
    if (event.matches) setIsDesktop(true);
    else setIsDesktop(false);
  };

  useEffect(() => {
    desktopMediaQuery.addEventListener("change", comproveMedia);

    if (desktopMediaQuery.matches) setIsDesktop(true);

    return () => {
      desktopMediaQuery.removeEventListener("change", comproveMedia);
    };
  }, []);

  return isDesktop;
};