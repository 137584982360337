export const TermsAndConditions = () => (
  <section className="bg-white pt-32 px-[5%] sm:px-[3%] md:pt-40 pb-28 md:pb-40">
    <div className="w-full max-w-[80rem] mx-auto space-y-12">
      <div className="space-y-6">
        <h2 className="text-2xl/tight lg:text-3xl/tight font-medium">
          Términos y condiciones de uso de Yoshi
        </h2>
        <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
          Última actualización: 24 de octubre del 2024.
        </p>
        <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
          Estos Términos y Condiciones regulan el uso del servicio Yoshi Cash.
          Al acceder o utilizar nuestro servicio, usted acepta estos Términos y
          Condiciones en su totalidad. Si no está de acuerdo con alguna parte de
          estos términos, no debe utilizar nuestro servicio.
        </p>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">1. Descripción del Servicio</h3>
        <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
          Yoshi Cash es un servicio de pago sin efectivo que permite a los
          usuarios realizar compras en el estadio de manera fácil y segura.
          Aceptamos pagos directos con todas las tarjetas de débito y crédito.
          Si el usuario no cuenta con tarjetas bancarias, puede utilizar
          nuestros cajeros automáticos inversos para obtener una tarjeta Yoshi
          (monedero electrónico) y efectuar compras dentro del evento.
        </p>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">2. Uso del Servicio</h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Registro:</strong> Es posible que se le solicite
            proporcionar información personal para utilizar ciertos servicios.
            Usted se compromete a que dicha información sea precisa y
            actualizada.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Responsabilidad del Usuario:</strong> Usted es responsable
            de todas las actividades que ocurran bajo su cuenta o tarjeta Yoshi.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">
          3. Tarjeta Yoshi (Monedero Electrónico)
        </h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Obtención:</strong> Disponible en nuestros cajeros
            automáticos inversos dentro de los eventos donde operamos.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Uso:</strong> Solo válida para compras dentro del evento.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Saldo:</strong> El saldo no es transferible y solo puede ser
            utilizado como se especifica en estos términos.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">4. Reembolsos</h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Solicitud:</strong> Si al finalizar su visita tiene saldo
            restante en su tarjeta Yoshi, puede solicitar el reembolso del monto
            sobrante.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Método y Cobertura:</strong> Los reembolsos se harán
            mediante transferencia electrónica a cuentas bancarias mexicanas
            solamente. No se podrá utilizar otro método de reembolso.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Proceso:</strong> Para solicitar un reembolso, comuníquese a
            través de WhatsApp al +52 729 628 6929.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Tiempo de Procesamiento:</strong> Los reembolsos se
            realizarán dentro de un plazo de 10 días hábiles a partir de la
            solicitud.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Condiciones:</strong> Es posible que se apliquen tarifas o
            cargos administrativos al procesar su reembolso.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">5. Pagos y Transacciones</h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Métodos Aceptados:</strong> Todas las tarjetas de débito y
            crédito reconocidas.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Seguridad:</strong> Nos comprometemos a proteger la
            información de pago de nuestros usuarios mediante protocolos de
            seguridad avanzados.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">
          6. Limitaciones de Responsabilidad
        </h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            No nos hacemos responsables por pérdidas indirectas, incidentales o
            consecuentes que puedan surgir del uso de nuestro servicio.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            No asumimos responsabilidad por fallas técnicas o interrupciones del
            servicio.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">
          7. Modificaciones al Servicio y Términos
        </h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            Nos reservamos el derecho de modificar o descontinuar el servicio,
            total o parcialmente, en cualquier momento y sin previo aviso.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            Los Términos y Condiciones pueden ser actualizados; cualquier cambio
            será efectivo inmediatamente después de su publicación en nuestro
            sitio web yoshicash.com.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">8. Propiedad Intelectual</h3>
        <ol className="list-outside list-disc pl-5 text-gray-400">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            Todos los derechos reservados. El contenido y materiales del
            servicio están protegidos por las leyes de propiedad intelectual
            aplicables.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">9. Ley Aplicable y Jurisdicción</h3>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            Estos términos se regirán e interpretarán de acuerdo con las leyes
            vigentes en México.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            Cualquier disputa será sometida a la jurisdicción exclusiva de los
            tribunales competentes en Zapopan, Jalisco.
          </li>
        </ol>
      </div>
      <div className="space-y-4">
        <h3 className="text-xl font-medium">10. Contacto</h3>
        <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
          Si tiene preguntas o inquietudes sobre estos Términos y Condiciones,
          por favor contáctenos a través de:
        </p>
        <ol className="list-outside list-disc pl-5 text-gray-400 space-y-2">
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Correo Electrónico:</strong> ayuda@yoshicash.com.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>WhatsApp:</strong> +52 729 628 6929.
          </li>
          <li className="text-base/relaxed lg:text-lg/relaxed">
            <strong>Sitio Web:</strong> yoshicash.com.
          </li>
        </ol>
      </div>
    </div>
  </section>
);
