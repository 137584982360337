import { FC } from "react";
import { Link } from "react-router-dom";
import Yoshi from "../../../../resources/logos/yoshi.svg";
import YoshiLight from "../../../../resources/logos/yoshiLight.svg";
import { LanguageSelector } from "./components";
import { TopbarDesktopProps } from "../../interfaces";

export const TopbarDesktop: FC<TopbarDesktopProps> = (props) => {
  const { isDarkTheme, isLanding, t } = props;

  return (
    <div
      className={`${
        isDarkTheme ? "bg-[#0F172A]" : "bg-white"
      } hidden md:block w-full fixed h-24 px-[3%] top-0 left-0 z-[1000]`}
    >
      <div className="h-full flex items-center max-w-[80rem] mx-auto">
        <div className="flex justify-start w-full gap-x-20 lg:gap-x-32">
          <Link to="/" className="flex items-center">
            {isDarkTheme ? (
              <img
                src={YoshiLight}
                className="shrink-0 h-16 cursor-pointer"
                alt="Yoshi logo"
              />
            ) : (
              <img
                src={Yoshi}
                className="shrink-0 h-16 cursor-pointer"
                alt="Yoshi logo"
              />
            )}
          </Link>
          <LanguageSelector isDarkTheme={isDarkTheme} isDesktop={true} />
        </div>
        <nav className="w-full flex justify-end gap-8 lg:gap-10 text-sm lg:text-base">
          <Link
            id="nav-terminals-desktop"
            className={`${
              isDarkTheme
                ? "text-white hover:text-gray-300"
                : "text-gray-500 hover:text-gray-700"
            }`}
            to={isLanding ? "#terminals" : "/#terminals"}
          >
            {t("home.terminals")}
          </Link>
          {/* <Link
            id="nav-atm-desktop"
            className={`${
              isDarkTheme
                ? "text-white hover:text-gray-300"
                : "text-gray-500 hover:text-gray-700"
            }`}
            to={isLanding ? "#atm" : "/#atm"}
          >
            {t("home.atm")}
          </Link> */}
          <Link
            id="nav-data-desktop"
            className={`${
              isDarkTheme
                ? "text-white hover:text-gray-300"
                : "text-gray-500 hover:text-gray-700"
            }`}
            to={isLanding ? "#data" : "/#data"}
          >
            {t("home.data")}
          </Link>
          <a
            id="nav-refunds-desktop"
            className="text-primary hover:text-primary/80"
            href="https://wa.me/5217296286929?text=Hola"
            target="_blank"
          >
            {t("home.refunds")}
          </a>
        </nav>
      </div>
    </div>
  );
};
