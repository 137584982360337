import {
  Navigate,
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import Landing from "./components/Landing/Landing";
import Terms from "./components/Terms/Terms";

const RootLayout = () => {
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <Landing /> },
      { path: "/terminos", element: <Terms /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
]);
