import { FC } from "react";
import Increase from "../../../resources/images/increase.webp";
import { SectionProps } from "../../../interfaces";

export const IncreaseConsumption: FC<SectionProps> = ({ t }) => {
  return (
    <section className="bg-purple-50/30 py-16 lg:py-24 px-[5%] sm:px-[3%]">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-12 md:gap-16 max-w-[80rem] mx-auto">
        <div className="size-full order-2 md:order-1">
          <img
            src={Increase}
            className="h-64 mx-auto object-contain"
            alt="Increase consumption"
          />
        </div>
        <div className="order-1 md:order-2">
          <h2 className="text-2xl/tight lg:text-3xl/tight font-medium">
            {t("home.increaseConsumption")}
          </h2>
          <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400 mt-6">
            {t("home.cashlessPaymentSystems")}
          </p>
          <p className="text-xs text-gray-400 mt-8">
            {t("home.seeCompleteVisaStudy")}
          </p>
        </div>
      </div>
    </section>
  );
};
