import { FC } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { SectionProps } from "../../../interfaces";

export const Hero: FC<SectionProps> = (props) => {
  const { t, isDesktop } = props;

  return (
    <header className="bg-white pt-32 px-[5%] sm:px-[3%] md:pt-40 lg:pt-48 pb-28 lg:pb-48 overflow-x-hidden">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 items-center max-w-[80rem] mx-auto">
        <div>
          <h1 className="text-4xl/tight lg:text-[3.125rem]/tight font-medium">
            <span className="text-primary font-medium">Yoshi</span>
            {`, ${t("home.yoshiDescription")}`}
          </h1>
          <p className="text-base lg:text-lg text-gray-400 mt-6">
            {t("home.facilitates")}
          </p>
          <div className="flex flex-wrap items-center gap-x-10 gap-y-5 mt-6">
            <a
              id={`start-to-sell-${isDesktop ? "desktop" : "mobile"}`}
              href="https://form.typeform.com/to/vk1tRebX"
              target="_blank"
              className="bg-primary hover:bg-primary/80 text-white flex justify-center items-center w-full sm:w-44 md:w-full lg:w-44 py-3 text-sm rounded-2xl"
            >
              {t("home.startToSell")}
            </a>
            <a
              id={`see-how-it-works-${isDesktop ? "desktop" : "mobile"}`}
              href="#how-it-works"
              className="flex mx-auto sm:mx-0 md:mx-auto lg:mx-0 items-center gap-1.5 text-primary hover:text-primary/80"
            >
              <span className="text-sm">{t("home.seeHowItWorks")}</span>
              <ChevronRightIcon className="mt-0.5 size-3.5 stroke-[3]" />
            </a>
          </div>
        </div>
        <div className="relative h-full flex justify-center items-center">
          <svg
            data-w-id="f5457c24-725a-49e2-e6b0-9fe6bd5b8b89"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 24 24"
            fill="none"
            className="hidden text-[#fb6f3d] w-5 md:block absolute md:-top-[4%] lg:-top-[10%] md:-left-[4%]"
          >
            <rect
              x="12"
              y="1.41421"
              width="14.9702"
              height="14.9702"
              transform="rotate(45 12 1.41421)"
              stroke="currentColor"
              strokeWidth="2"
              className="rect"
            ></rect>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 407 407"
            fill="none"
            className="hidden md:block absolute md:w-[300px] lg:w-[407px] text-[#aea1ea] md:top-[7%] lg:-top-[5%] md:left-[1%] lg:-left-[6%]"
          >
            <circle
              opacity="0.3"
              cx="203.5"
              cy="203.5"
              r="193.5"
              stroke="currentColor"
              strokeWidth="20"
            ></circle>
          </svg>
          <svg
            data-w-id="07147f67-72e3-4c02-720d-7baf30d512cc"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 37 37"
            fill="currentColor"
            className="text-primary w-6 absolute right-0 -bottom-[17%] md:-bottom-[5%] lg:-bottom-[10%] xl:-bottom-[16%]"
          >
            <rect
              x="18.5"
              y="0.5"
              width="25.4554"
              height="25.4554"
              transform="rotate(45 18.5 0.5)"
              fill="currentColor"
            ></rect>
          </svg>
          <div className="w-full bg-white rounded-lg overflow-hidden shadow-black/5 shadow-xl z-10">
            <div className="size-full relative pb-[56.25%]">
              <video className="size-full m-auto absolute" autoPlay muted loop playsInline controls>
                <source
                  src={require("../../../resources/videos/yoshi-presentation.mp4")}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
